<template>
  <div class="team">
    <div class="banner">
      <div class="font-weight-bold">
        Team
      </div>
    </div>
    <div class="team_inner">
      <div class="title">
        Leadership Team
      </div>
      <div class="row justify-content-center">
        <div v-for="item in team_inner" :key="item.name" class="col-6">
          <router-link v-if="item.id" :to="{name:'teamDetails',params:{id:item.id}}">
            <div class="team_item">
              <div class="head">
                <img class="img" :src="item.img" alt="">
              </div>
              <div class="text">
                <div class="name font-weight-bold">
                  {{item.name}}
                </div>
                <div class="info">{{item.info}}</div>
              </div>
            </div>
          </router-link>
          <div v-else class="team_item">
            <div class="head">
              <img class="img" :src="item.img" alt="">
            </div>
            <div class="text">
              <div class="name font-weight-bold">
                {{item.name}}
              </div>
              <div class="info">{{item.info}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="board">
      <div class="title">
        Advisors
      </div>
      <div class="row justify-content-center">
        <div v-for="(item,index) in board_inner" :key="item.name" :class="index >99 ? 'col-9' :'col-6'">
          <router-link v-if="item.id" :to="{name:'teamDetails',params:{id:item.id}}">
            <div class="team_item">
              <div class="head">
                <img class="img" :src="item.img" alt="">
              </div>
              <div class="text">
                <div class="name font-weight-bold">
                  {{item.name}}
                </div>
                <div class="info">{{item.info}}</div>
              </div>
            </div>
          </router-link>
          <div v-else class="team_item">
            <div class="head">
              <img class="img" :src="item.img" alt="">
            </div>
            <div class="text">
              <div class="name font-weight-bold">
                {{item.name}}
              </div>
              <div class="info">{{item.info}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="board">
      <div class="title">
        Board of Directors
      </div>
      <div class="row justify-content-center">
        <div v-for="item in position_inner" :key="item.name" class="col-6">
          <div class="team_item">
            <div class="head">
              <img class="img" :src="item.img" alt="">
            </div>
            <div class="text">
              <div class="name font-weight-bold">
                {{item.name}}
              </div>
              <div class="info">{{item.info}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {
      team_inner: [
        {
          img: require('../assets/team/ShirleyLiuPhD.png'),
          name: 'Shirley Liu, PhD',
          info: 'Co-founder & Chief Executive Officer',
          id: 1,
        }, {
          img: require('../assets/team/TedXiaoPhD.png'),
          name: 'Ted Xiao, PhD',
          info: 'Co-founder & Chief Technology Officer',
          id: 2,
        }, {
          img: require('../assets/team/XingfengBao.jpg'),
          name: 'Xingfeng Bao, PhD',
          info: 'Head of Biology',
          id: 3,
        }, {
          img: require('../assets/team/JieChen.jpg'),
          name: 'Jie Chen, MD, MS',
          info: 'Chief Technical Operations & Quality Officer',
          id: 5,
        }, {
          img: require('../assets/team/YingGong.jpg'),
          name: 'Ying Gong, PhD',
          info: 'Chief Business Officer',
          id: 6,
        }, {
          img: require('../assets/team/SherlockHuPhD.png'),
          name: 'Sherlock Hu, PhD',
          info: 'Chief Information Officer',
          id: 7,
        }, {
          img: require('../assets/team/HuaweiQiu.jpg'),
          name: 'Huawei Qiu, PhD',
          info: 'SVP Head of Biologics Discovery',
          id: 8,
        }, {
          img: require('../assets/team/HongXiao.png'),
          name: 'Hong Xiao, MS',
          info: 'Head of Clinical Operations',
          id: 9,
        },
      ],
      board_inner: [
        {
          img: require('../assets/team/MylesBrownMD.png'),
          name: 'Myles Brown, MD',
          info: 'Emil Frei III Professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School',
          id: 11,
        }, {
          img: require('../assets/team/GordonFreemanPhD.png'),
          name: 'Gordon Freeman, PhD',
          info: 'Professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School',
          id: 12,
        }, {
          img: require('../assets/team/LewisLanier.png'),
          name: 'Lewis Lanier, PhD',
          info: 'J. Michael Bishop, MD, Distinguished Professor in Microbiology and Immunology, UCSF',
          id: 13,
        }, {
          img: require('../assets/team/KaiLiPhD.png'),
          name: 'Kai Li, PhD',
          info: 'Paul M. Wythes and Marcia R. Wythes Professor of Computer Science at Princeton University',
          id: 14,
        }, {
          img: require('../assets/team/MikeVarney.jpg'),
          name: 'Mike Varney, PhD',
          info: 'Former EVP and Head, Genentech Research and Early Development',
          id: 15,
        }, {
          img: require('../assets/team/JulieCherrington.jpg'),
          name: 'Julie Cherrington, PhD',
          info: 'Venture Partner, Brandon Capital Partners',
          id: 16,
        }, {
          img: require('../assets/team/AlessandraCesano.png'),
          name: 'Alessandra Cesano, MD, PhD',
          info: 'CMO, ESSA Pharmaceuticals',
          id: 17,
        }, {
          img: require('../assets/team/LeilaAlland.png'),
          name: 'Leila Alland, MD',
          info: 'Board of Directors of Abeona Therapeutics, Board of Directors of Radiopharm Theranostics',
          id: 18,
        }, {
          img: require('../assets/team/JamesWooldridge.png'),
          name: 'James Wooldridge, MD',
          info: 'Chief Medical Officer of Checkmate Pharmaceuticals, Chief Medical Officer of Aeglea BioTherapeutics',
          id: 19,
        },
      ],
      position_inner: [
        {
          img: require('../assets/team/ShirleyLiuPhD.png'),
          name: 'Shirley Liu, PhD',
          info: 'CEO'
        },
        {
          img: require('../assets/team/TedXiaoPhD.png'),
          name: 'Ted Xiao, PhD',
          info: 'CTO'
        },
        {
          img: require('../assets/team/Christopher.jpg'),
          name: 'Christopher K. Mirabelli, PhD',
          info: 'HealthCare Ventures'
        },
        {
          img: require('../assets/team/XiaojunLi.png'),
          name: 'Xiaojun Li',
          info: 'IDG Capital'
        },
        {
          img: require('../assets/team/KaiJiang.png'),
          name: 'Kai Jiang',
          info: 'Coatue Management'
        },
        {
          img: require('../assets/team/CanZheng.jpg'),
          name: 'Can Zheng',
          info: 'Linear Capital'
        },
        {
          img: require('../assets/team/JingLiu.png'),
          name: 'Jing Liu',
          info: '3W Investment'
        },
      ],
    }
  },
  components: {
    Footers,
  }
}
</script>
<style lang="scss" scope>
.team {
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .banner {
    color: #fff;
    height: 256px;
    background: url(../assets/team/headline_bg.jpg) no-repeat center
      center/cover;
    font-size: 60px;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
  .team_inner {
    padding: 50px 0;
  }
  .team_item {
    text-align: center;
    margin: 40px 0;
    .name {
      font-size: 30px;
      margin-top: 30px;
    }
    .info {
      color: #3e3e3e;
      font-size: 25px;
    }
    .head {
      .img {
        width: 200px;
      }
    }
  }
  .board {
    .row {
      padding: 80px 20px;
    }
  }
  .title {
    font-size: 50px;
    padding: 70px 0;
    background-color: #f6f8f9;
    padding-left: 40px;
    font-weight: bold;
  }
  .row {
    margin: 0;
  }
}
</style>
